<template>
  <div>
    <dx-util-popup
      ref="packageContentPopupRef"
      :drag-enabled="false"
      :close-on-outside-click="true"
      :show-close-button="true"
      :show-title="true"
      :title="popupTitle"
      :full-screen="true"
      :position="'center'"
      @shown="packageContentPopupShown"
      @hidden="packageContentPopupHidden"
    >
      <div>
        <dx-data-grid
          id="packageContent"
          ref="packageContentGrid"
          :data-source="packageContent"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :show-borders="true"
          :row-alternation-enabled="true"
        >
          <dx-column data-field="itemAction" caption="Action" alignment="center" :width="90" />
          <dx-column data-field="smallImage" cell-template="imageCellTemplate" caption="Image" alignment="center" :allow-sorting="false" :width="70" />
          <template #imageCellTemplate="{data}">
            <div>
              <img class="rounded" :src="data.value || defaultImageUrl" style="height:4rem">
            </div>
          </template>
          <dx-column data-field="title" caption="Title" cell-template="titleCellTemplate" width="300" />
          <template #titleCellTemplate="{data}">
            <div class="dx-product-title">
              {{ data.value }}
            </div>
          </template>
          <dx-column
            data-field="tracking"
            alignment="left"
            :width="160"
            cell-template="trackingDetialsTemplate"
            :allow-filtering="false"
          />
          <template #trackingDetialsTemplate="{ data }">
            <div v-if="data.value && data.value !== '-'">
              {{ data.value }}
            </div>
            <div v-else>
              <dx-util-button
                text="Tracking Details"
                type="success"
                styling-mode="outlined"
                :element-attr="btnElementAttr"
                @click="showTrackingDetails(data.data)"
              />
            </div>
          </template>

          <dx-column data-field="palletId" caption="Pallet Id" />
          <dx-column data-field="orderNumber" />
          <dx-column data-field="upcCode" caption="UPC" :width="120" />
          <dx-column data-field="asin" caption="ASIN" alignment="left" :width="120" cell-template="asinCellTemplate" />
          <template #asinCellTemplate="{data}">
            <a target="blank" :href="`https://www.amazon.com/gp/product/${data.value}`">
              {{ data.value }}
            </a>
          </template>
          <!-- <dx-column data-field="quantity" caption="QTY" alignment="right" :width="80" /> -->
          <dx-column data-field="uploadedQty" caption="Uploaded" />
          <dx-column data-field="sortedQty" caption="Sorted" />
          <dx-column data-field="clientShipmentItemId" caption="Reference" alignment="right" :width="90" />
          <dx-column data-field="shippedQty" caption="Shipped" />
          <dx-column data-field="accountNo" />
          <dx-column data-field="suiteNo" />
          <dx-column data-field="companyName" />
          <dx-column data-field="storeName" />
          <dx-column data-field="unloadedAt" caption="Delivered At" data-type="date" cell-template="deliverCellTemplate" />
          <dx-column data-field="sortedAt" data-type="date" cell-template="deliverCellTemplate" />
          <template #deliverCellTemplate="{data}">
            <div class="text-success">
              {{ getDate(data.value) }}
            </div>
          </template>
        </dx-data-grid>

      </div>
    </dx-util-popup>
    <dx-util-popup
      ref="sortedItemsPopupRef"
      :drag-enabled="true"
      :show-close-button="true"
      :close-on-outside-click="true"
      :show-title="true"
      :width="1000"
      :height="'auto'"
      title="Sorted Items"
      content-template="popup-content"
    >
      <dx-util-position at="center" my="center" />
      <template #popup-content>
        <dx-util-scroll-view height="100%" width="100%">
          <div class="d-flex flex-column">
            <dx-data-grid
            id="sortedItemspackageContent"
            ref="sortedItemspackageContentGrid"
            :data-source="sortedItemsDetails"
            :allow-column-reordering="true"
            :allow-column-resizing="true"
            :column-auto-width="true"
            :show-column-lines="true"
            :show-row-lines="true"
            :show-borders="true"
            :row-alternation-enabled="true"
          >
            <dx-column data-field="tracking" />
            <dx-column data-field="orderNumber" />
            <dx-column data-field="palletId" caption="Pallet Id" />
            <dx-column data-field="sortedQty" caption="Sorted" width="80" />
            <!-- <dx-column data-field="unloadedAt" caption="Delivered At" data-type="date" width="110" cell-template="deliverCellTemplate" /> -->
            <dx-column data-field="sortedAt" data-type="date" width="110" cell-template="deliverCellTemplate" />
            <template #deliverCellTemplate="{data}">
              <div class="text-success">
                {{ getDate(data.value) }}
              </div>
            </template>
          </dx-data-grid>
          </div>
        </dx-util-scroll-view>
      </template>
    </dx-util-popup>
  </div>
</template>

<script>
import sortItemService from '@/http/requests/inbound/sort-item.service'

// import { Notify } from '@/@robustshell/utils'

export default {
  components: {
  },
  props: {
    componentId: {
      type: String,
      default: '',
    },
    packageContent: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      popupTitle: 'Package Content',
      sortedItemsDetails: [],
    }
  },
  computed: {
    packageContentPopup() {
      return this.$refs.packageContentPopupRef.instance
    },
  },
  watch: {
    componentId: {
      immediate: true,
      handler() {
        this.packageContentPopup.show()
      },
    },
  },
  methods: {
    packageContentPopupShown() {
      this.popupTitle = 'Package Content'
    },
    packageContentPopupHidden() {
    // Clear form when popup is closed
    },
    closePopup() {
      this.packageContentPopup.hide()
    },
    async showTrackingDetails(item) {
      const result = await sortItemService.getByClientShipmentItemId(item.clientShipmentItemId)
      const { data } = result
      this.sortedItemsDetails.length = 0
      this.sortedItemsDetails = [...data.body]
      const popup = this.$refs.sortedItemsPopupRef.instance
      popup.show()
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
